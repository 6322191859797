import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AccessProfileIcon,
    AccessClipboardCheckIcon,
    AccessKeyIcon,
    LogoutIcon,
    MenuKiwiIcon,
    MenuHealthIcon,
    MenuHelloKlarityIcon,
    MenuContractLinkIcon,
    ProviderSearchIcon,
} from 'components/MenuIcons';
import { useNavigate, Outlet } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import s from './s.module.less';
import { PATH } from 'constants/path';
import ProviderStore from 'store/Provider';
import { Provider } from 'types/provider';
import I from 'assets/signup/Google-primary.svg';
import Logo from 'assets/logo/kh.svg';
import { EKey, getKey } from 'utils/localstore';
import AccessRoleStore from 'store/Access/role';
import AccessStore from 'store/Access/permission';
import { isSuperAdmin } from 'utils/access';
import { isLocalhost, isOpPortal, isProductionEnv } from 'utils/common';
import { redirecToLogout } from 'utils/redirecToLogout';
import { PermissionModuleKeys } from 'constants/access';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { clearUniprofileStepStorage } from 'utils/uniprofile';

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

enum MenuKey {
    APP = 'app',
    HEALTH = 'health',
    HELLO = 'hello',
    PROVIDER = 'provider',
    CHANNEL = 'channel',
    LIST_TO_CHANNEL = 'list-to-channel',
    SERVICES_TEMPLATE = 'services-template',
    PROVIDER_SEARCH = 'provider-search',
    HOME_CONFIG = 'home-config',
    AI_CONFIG = 'ai-config',
    HELLO_KLARIY_PROVIDERS = 'hello-klarity-provider',
    HELLO_KLARITY_PAGE_MANAGEMENT = 'hello-klarity-page-management',
    HELLO_KLARITY_PROVIDER_RESOURCES = 'hello-klarity-provider-resources',
    KLARITY_REVIEWS = 'klarity-reviews',
    KLARITY_ASSESSMENT = 'klarity-assessment',
    CONTRACT_LINK = 'contract-link',
    CONFIGURATIONS = 'configurations',
    EHR_ADD_ON_OPS = 'ehr-add-on',
    EHR_ADD_ON_PROVIDER = 'ehr-add-on',
}

enum AccessMenuKey {
    OPS_MEMBER = 'ops-member',
    DEV_WHITELIST = 'dev-whitelist',
    ROLES = 'roles',
    LOGOUT = 'logout',
}

const keyPathMap: Partial<Record<MenuKey | AccessMenuKey, string | Function>> =
{
    [MenuKey.PROVIDER_SEARCH]: `${PATH.OPERATION}/${PATH.PROVIDER_SEARCH}`,
    [MenuKey.PROVIDER]: `${PATH.OPERATION}/${PATH.PROVIDER}`,
    [MenuKey.CHANNEL]: `${PATH.OPERATION}/${PATH.CHANNEL}`,
    [MenuKey.SERVICES_TEMPLATE]: `${PATH.OPERATION}/${PATH.SERVICES_TEMPLATE}`,
    [MenuKey.HOME_CONFIG]: `${PATH.OPERATION}/${PATH.HOME_CONFIG}`,
    [MenuKey.AI_CONFIG]: `${PATH.OPERATION}/${PATH.AI_CONFIG}`,
    [MenuKey.CONFIGURATIONS]: `${PATH.OPERATION}/${PATH.CONFIGURATIONS}`,
    [MenuKey.HELLO]: `${PATH.OPERATION}/${PATH.HELLO_KLARITY}`,
    [MenuKey.HEALTH]: `${PATH.OPERATION}/${PATH.KIWI_HEALTH}`,
    [MenuKey.HELLO_KLARIY_PROVIDERS]: `${PATH.OPERATION}/${PATH.HELLO_KLARITY_PROVIDERS}`,
    [MenuKey.HELLO_KLARITY_PAGE_MANAGEMENT]: `${PATH.OPERATION}/${PATH.HELLO_KLARITY_PAGE_MANAGEMENT}`,
    [MenuKey.KLARITY_REVIEWS]: `${PATH.OPERATION}/${PATH.KLARITY_REVIEWS}`,
    [MenuKey.HELLO_KLARITY_PROVIDER_RESOURCES]: `${PATH.OPERATION}/${PATH.HELLO_KLARITY_PROVIDER_RESOURCES}`,
    [MenuKey.KLARITY_ASSESSMENT]: `${PATH.OPERATION}/${PATH.KLARITY_ASSESSMENT}`,
    [MenuKey.CONTRACT_LINK]: `${PATH.OPERATION}/${PATH.CONTRACT_LINK_PAGE}`,
    [MenuKey.EHR_ADD_ON_OPS]: `${PATH.OPERATION}/${PATH.EHR_ADD_ON_OPS}`,
    [AccessMenuKey.OPS_MEMBER]: `${PATH.OPERATION}/${PATH.OPS_MEMBER}`,
    [AccessMenuKey.DEV_WHITELIST]: `${PATH.OPERATION}/${PATH.DEV_WIHTELIST}`,
    [AccessMenuKey.ROLES]: `${PATH.OPERATION}/${PATH.ROLES}`,
    [AccessMenuKey.LOGOUT]: () => {
        const key = getKey(EKey.BOX_PRACTICE_TOKEN);
        localStorage.removeItem(key);
        clearUniprofileStepStorage();

        redirecToLogout();
    },
};
const rootSubmenuKeys = [MenuKey.APP, MenuKey.HEALTH, MenuKey.HELLO];
const LayoutPage: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    const [get] = ProviderStore.useStore();
    const provider: Provider = get('data') as Provider;
    const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed]);
    const [getRoleStore] = AccessRoleStore.useStore();
    const [getAccessStore] = AccessStore.useStore();
    const roles = getRoleStore('data');
    const access = getAccessStore('data') as Record<PermissionModuleKeys, boolean>;
    const userIsSuperAdmin = useMemo(() => isSuperAdmin(roles), [roles]);
    const [openKeys, setOpenKeys] = useState<MenuKey[]>([MenuKey.APP]);
    const onOpenChange = (keys: MenuKey[]) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const items: MenuItem[] = [
        getItem('Provider Search', MenuKey.PROVIDER_SEARCH, ProviderSearchIcon),
        getItem(
            'Kiwi Health web app',
            MenuKey.APP,
            MenuKiwiIcon,
            [
                getItem('Providers', MenuKey.PROVIDER),
                getItem('Channels', MenuKey.CHANNEL),
                access?.[PermissionModuleKeys.GENERAL_SERVICE] ||
                    access?.[PermissionModuleKeys.KLARITY_SERVICE]
                    ? getItem('Services template', MenuKey.SERVICES_TEMPLATE)
                    : null,
                getItem('Configurations', MenuKey.CONFIGURATIONS),
                access?.[PermissionModuleKeys.EHR_ADD_ON]
                    ? getItem('EHR & add-ons', MenuKey.EHR_ADD_ON_OPS)
                    : null,
            ].filter(Boolean),
        ),
        access?.[PermissionModuleKeys.KIWI_HEALTH_PAGE] ||
            access?.[PermissionModuleKeys.KIWI_HEALTH_PROVIDER]
            ? getItem('Kiwi Health.com', MenuKey.HEALTH, MenuHealthIcon)
            : null,
        getItem(
            'Hello Klarity.com',
            MenuKey.HELLO,
            MenuHelloKlarityIcon,
            [
                access?.[PermissionModuleKeys.KLARITY_PAGE_MANAGEMENT]
                    ? getItem('Page management', MenuKey.HELLO_KLARITY_PAGE_MANAGEMENT)
                    : null,
                access?.[PermissionModuleKeys.KLARITY_PROVIDER_AIRTABLE] ||
                    access?.[PermissionModuleKeys.KLARITY_PROVIDER_KIWI]
                    ? getItem('Providers', MenuKey.HELLO_KLARIY_PROVIDERS)
                    : null,
                access?.[PermissionModuleKeys.PROVIDER_REVIEW]
                    ? getItem('Reviews', MenuKey.KLARITY_REVIEWS)
                    : null,
                getItem('Assessment', MenuKey.KLARITY_ASSESSMENT),
                // access?.[PermissionModuleKeys.KLARITY_ASSESSMENT]
                //     ? getItem('Assessment', MenuKey.KLARITY_ASSESSMENT)
                //     : null,
                // todo: add this back when the permission is ready
                getItem('Provider resources', MenuKey.HELLO_KLARITY_PROVIDER_RESOURCES),
            ].filter(Boolean),
        ),
        access?.[PermissionModuleKeys.CONTRACT_LINK]
            ? getItem('Contract link', MenuKey.CONTRACT_LINK, MenuContractLinkIcon)
            : null,
        // getItem('Hello Klarity.com', MenuKey.HELLO, MenuHelloIcon),
    ].filter(Boolean);

    const accessItems = useMemo<MenuItem[]>(() => [
        getItem('Ops member', AccessMenuKey.OPS_MEMBER, AccessProfileIcon),
        getItem('Dev whitelist', AccessMenuKey.DEV_WHITELIST, AccessClipboardCheckIcon),
        userIsSuperAdmin ? getItem('Roles', AccessMenuKey.ROLES, AccessKeyIcon) : null,
        getItem('Logout', AccessMenuKey.LOGOUT, LogoutIcon),
        // getItem('List to Channels', MenuKey.LIST_TO_CHANNEL, ChannelsIcon),
    ], [userIsSuperAdmin]);

    const navigate = useNavigate();

    const [loadSettingData] = useLoadBasicData();

    useEffect(() => {
        loadSettingData();
    }, [loadSettingData]);

    const onClickMenu = useCallback(({ key }: { key: string }) => {
        const path: string | undefined | Function = keyPathMap[key as MenuKey | AccessMenuKey];
        if (path) {
            if (typeof path === 'function') {
                path();
            } else {
                setSelectedKeys([key]);
                navigate(path);
            }
        }
    }, [navigate]);

    useEffect(() => {
        const path = window.location.pathname;
        let key: string = MenuKey.PROVIDER;
        setOpenKeys([MenuKey.APP]);
        if (path.includes(MenuKey.PROVIDER_SEARCH)) {
            //setting also use this
            key = MenuKey.PROVIDER_SEARCH;
        }
        if (path.includes(MenuKey.LIST_TO_CHANNEL)) {
            //setting also use this
            key = MenuKey.LIST_TO_CHANNEL;
        }
        if (path.includes(MenuKey.CHANNEL)) {
            //setting also use this
            key = MenuKey.CHANNEL;
        }
        if (path.includes(MenuKey.SERVICES_TEMPLATE)) {
            //setting also use this
            key = MenuKey.SERVICES_TEMPLATE;
        }
        if (path.includes(MenuKey.HOME_CONFIG)) {
            //setting also use this
            key = MenuKey.HOME_CONFIG;
        }
        if (path.includes(MenuKey.AI_CONFIG)) {
            key = MenuKey.AI_CONFIG;
        }
        if (path.includes(MenuKey.CONFIGURATIONS)) {
            key = MenuKey.CONFIGURATIONS;
        }
        if (path.includes(MenuKey.HELLO_KLARIY_PROVIDERS)) {
            setOpenKeys([MenuKey.HELLO]);
            key = MenuKey.HELLO_KLARIY_PROVIDERS;
        }
        if (path.includes(MenuKey.HELLO_KLARITY_PAGE_MANAGEMENT)) {
            setOpenKeys([MenuKey.HELLO]);
            key = MenuKey.HELLO_KLARITY_PAGE_MANAGEMENT;
        }
        if (path.includes(MenuKey.CONTRACT_LINK)) {
            key = MenuKey.CONTRACT_LINK;
        }
        if (path.includes(MenuKey.EHR_ADD_ON_OPS)) {
            //setting also use this
            key = MenuKey.EHR_ADD_ON_OPS;
        }

        if (path.includes(MenuKey.KLARITY_REVIEWS)) {
            setOpenKeys([MenuKey.HELLO]);
            key = MenuKey.KLARITY_REVIEWS;
        }

        if (path.includes(MenuKey.KLARITY_ASSESSMENT)) {
            setOpenKeys([MenuKey.HELLO]);
            key = MenuKey.KLARITY_ASSESSMENT;
        }

        // access
        if (path.includes(AccessMenuKey.OPS_MEMBER)) {
            //setting also use this
            key = AccessMenuKey.OPS_MEMBER;
        }
        if (path.includes(AccessMenuKey.DEV_WHITELIST)) {
            //setting also use this
            key = AccessMenuKey.DEV_WHITELIST;
        }
        if (path.includes(AccessMenuKey.ROLES)) {
            //setting also use this
            key = AccessMenuKey.ROLES;
        }
        if (path.includes(MenuKey.HELLO_KLARITY_PROVIDER_RESOURCES)) {
            setOpenKeys([MenuKey.HELLO]);
            key = MenuKey.HELLO_KLARITY_PROVIDER_RESOURCES;
        }
        setSelectedKeys([key]);
    }, []);

    const renderSliderFooter = useMemo(() => {
        return roles?.length ?
            <Menu onClick={onClickMenu} selectedKeys={selectedKeys} mode="inline" items={accessItems} /> :
            <div className={s.providerMenuFooter}>
                <div><img src={provider?.googleAvatar || I} /></div>
                <div>{provider?.firstName}</div>
            </div>;
    }, [accessItems, onClickMenu, provider, roles, selectedKeys]);

    if (!isOpPortal() && !isLocalhost()) {
        return null;
    }

    return (
        <Layout className={s.layout}>
            <Sider theme="light" className={s.sider} collapsed={collapsed}>
                <div className={s.siderContent}>
                    <div className={s.menuHead}>
                        {collapsed && (
                            <img
                                onClick={() => {
                                    toggleCollapsed();
                                }}
                                src={Logo}
                                className={s.logoS}
                            />
                        )}
                        {/* <Button size="small" onClick={toggleCollapsed}>
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button> */}
                        {/* { !collapsed && <span className={s.title}>Kiwi Health</span> } */}
                        {!collapsed && (
                            <img
                                onClick={() => {
                                    toggleCollapsed();
                                }}
                                src="https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/logo_klarity_ops_572x184.png"
                                className={s.logo}
                            />
                        )}
                    </div>
                    <Menu
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        onClick={onClickMenu}
                        selectedKeys={selectedKeys}
                        mode="inline"
                        items={items}
                    />
                </div>
                <div className={s.siderFooter}>{renderSliderFooter}</div>
            </Sider>
            <Layout className={s.siteLayout}>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default LayoutPage;
